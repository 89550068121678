import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.timers.js";
import dayjs from "~/utils/tools/dayjs";
import useNumberFormatters from "~/functions/useNumberFormatters";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
export default defineComponent({
  props: {
    latestIEXPrice: {
      type: Number,
      "default": 0
    },
    latestIEXChange: {
      type: Number,
      "default": 0
    },
    latestIEXChangePct: {
      type: Number,
      "default": 0
    },
    latestIEXSource: {
      type: String,
      "default": "Close"
    },
    latestIEXTime: {
      type: Number,
      "default": 0
    },
    lastCiqPrice: {
      type: Number,
      "default": 0
    },
    lastCiqChange: {
      type: Number,
      "default": 0
    },
    lastCiqChangePercent: {
      type: Number,
      "default": 0
    },
    lastCiqTime: {
      type: String,
      "default": ""
    },
    colWrapperClass: {
      type: String,
      "default": "text-right"
    },
    hidePrice: {
      type: Boolean,
      "default": false
    },
    loading: {
      type: Boolean,
      "default": false
    },
    activeCurrencyCode: {
      type: String,
      "default": "USD"
    }
  },
  setup: function setup(props) {
    var _useNumberFormatters = useNumberFormatters(),
      formatPercent = _useNumberFormatters.formatPercent,
      dynamicTwoDigitCurrencyFormatter = _useNumberFormatters.dynamicTwoDigitCurrencyFormatter;
    var isGain = ref(false);
    var isLoss = ref(false);
    var _useDateTimeFormatter = useDateTimeFormatters(),
      epochToDateTime_EST = _useDateTimeFormatter.epochToDateTime_EST,
      utcEpochToLongDate = _useDateTimeFormatter.utcEpochToLongDate; // const activeCurrencyCode = ref(props.activeCurrencyCode)
    var formatCurrency = computed(function () {
      return dynamicTwoDigitCurrencyFormatter(props.activeCurrencyCode);
    });
    var wrapperStyle = {
      "line-height": "1.3"
    };
    watch(function () {
      return props.latestIEXPrice;
    }, function (price, prevPrice) {
      if (price && prevPrice && price !== prevPrice) {
        if (price > prevPrice) {
          isGain.value = true;
          setTimeout(function () {
            isGain.value = false;
          }, 1000);
        } else {
          isLoss.value = true;
          setTimeout(function () {
            isLoss.value = false;
          }, 1000);
        }
      }
    });
    var useIEX = computed(function () {
      var isOlderThan18HoursAgo = dayjs().diff(dayjs(props.latestIEXTime), "hour") > 18;
      var isNumber = typeof props.latestIEXPrice === "number";
      var isDifferentThanLastCiqPrice = props.latestIEXPrice !== props.lastCiqPrice;
      var isNotZero = props.latestIEXPrice !== 0;
      return !isOlderThan18HoursAgo && isNumber && isDifferentThanLastCiqPrice && isNotZero;
    });
    return {
      formatPercent: formatPercent,
      epochToDateTime_EST: epochToDateTime_EST,
      utcEpochToLongDate: utcEpochToLongDate,
      formatCurrency: formatCurrency,
      isGain: isGain,
      isLoss: isLoss,
      wrapperStyle: wrapperStyle,
      useIEX: useIEX
    };
  },
  computed: {
    gain: function gain() {
      if (this.useIEX) {
        return this.latestIEXChange > 0;
      }
      return this.lastCiqChange ? this.lastCiqChange > 0 : false;
    }
  }
});